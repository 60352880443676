import React, { useState } from 'react';
import {
  Alert,
  Button,
  Checkbox,
  Modal,
  notification,
  Space,
  Upload,
} from 'antd';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useUserSession } from 'technical/hooks/use-user-session';
import getScenarioListFromZone from 'business/scenarios/services/scenario-list';
import { PageLayout } from 'ui/layout';
import { hasEditingRights } from 'technical/auth/authorization';
import { ImportOutlined, InboxOutlined } from '@ant-design/icons';
import { queryClient } from 'technical/react-query';
import { uploadCsv } from 'business/scenarios/services/scenario-creation';
import ScenarioList from './scenario-list';
import ScenariosCreationModal from './scenario-creation';

export default function ScenariosPage() {
  const { t } = useTranslation('scenarios');
  const { zone, tenant, role } = useUserSession();
  const [isModalCreationVisible, setModalCreationIsVisible] = useState(false);
  const [isModalImportVisible, setModalImportIsVisible] = useState(false);
  const [isSameZoneChecked, setSameZoneChecked] = useState(false);

  const toggleModalCreation = () =>
    setModalCreationIsVisible((current) => !current);
  const toggleModalImport = () =>
    setModalImportIsVisible((current) => !current);

  const checkedSameZone = () => setSameZoneChecked((current) => !current);

  const scenarioListQuery = useQuery(
    ['scenarios', tenant, { zoneId: zone }],
    () => getScenarioListFromZone(tenant, zone),
  );

  const uploadScenariosMutation = useMutation(
    async (options: any) => {
      await uploadCsv(tenant, zone, isSameZoneChecked, options.file);
      toggleModalImport();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('zones');
        queryClient.invalidateQueries('gears');
        queryClient.invalidateQueries('tree');
        queryClient.invalidateQueries(['scenarios', tenant, { zoneId: zone }]);
        queryClient.invalidateQueries('zone');
      },
      onError: (err: Error) => {
        notification.error({
          message: err.message,
          description: 'File malformed, please check your file',
        });
      },
    },
  );

  return (
    <PageLayout
      title={t('list.title')}
      leftButton={
        hasEditingRights(role) ? (
          <Button
            type="primary"
            data-testid="new-scenario"
            onClick={toggleModalCreation}
          >
            {t('list.creation')}
          </Button>
        ) : null
      }
      rightButton={
        hasEditingRights(role) ? (
          <Button onClick={toggleModalImport} icon={<ImportOutlined />}>
            {t('list.import')}
          </Button>
        ) : null
      }
    >
      {scenarioListQuery.status === 'error' ? (
        <Alert type="error" message={t('list.error')} />
      ) : null}
      <ScenarioList
        loading={scenarioListQuery.status === 'loading'}
        scenarios={scenarioListQuery.data}
      />
      {isModalCreationVisible && (
        <ScenariosCreationModal onClose={toggleModalCreation} />
      )}

      {isModalImportVisible && (
        <Modal
          open
          title={t('import.modal.title')}
          onCancel={toggleModalImport}
          footer={[
            <Button key="cancel" onClick={toggleModalImport}>
              {t('import.modal.cancel')}
            </Button>,
            <Button key="template" type="primary">
              <Link
                to="/templates/template_import_scenario_spectral.csv"
                target="_blank"
                download
              >
                {t('import.modal.template')}
              </Link>
            </Button>,
          ]}
        >
          <Space
            direction="vertical"
            style={{ width: '100%' }}
          >
            <Upload.Dragger
              accept=".csv,.zip"
              customRequest={uploadScenariosMutation.mutate}
            >
              <InboxOutlined />
              <p>{t('import.modal.upload')}</p>
            </Upload.Dragger>
            <Checkbox onChange={checkedSameZone}>
              {t('import.modal.zone')}
            </Checkbox>
          </Space>
        </Modal>
      )}
    </PageLayout>
  );
}
