import { Scenario, Zone, Task, Step } from 'business/type';
import {
  ScenarioInputCreate,
  TaskInputCreate,
  StepInputCreate,
  ScenarioInputCopy,
} from 'business/scenarios/services/type';
import { api } from 'technical/api';
import i18next from 'translations';
import { ZoneInputCreate } from 'business/zones/services/type';

export async function getZoneChilds(tenant: string, id: number) {
  const zoneChilds = api(tenant)
    .get<Zone[]>(`/v1/zones/childs/${id}`)
    .then((response) => response.data);

  const zones = await Promise.resolve(zoneChilds);
  return zones;
}

export async function createZone(tenant: string, input: ZoneInputCreate) {
  const { data: zone } = await api(tenant).post<Pick<Zone, 'id'>>(
    '/v1/zones',
    input,
  );
  return zone;
}

export async function createScenario(
  tenant: string,
  input: ScenarioInputCreate,
) {
  const scenario = await api(tenant).post<Pick<Scenario, 'reference'>>(
    '/v1/scenarios',
    input,
  );
  const taskInput: TaskInputCreate = {
    label: i18next.t('scenarios:creation.modal.new-task.label'),
    scenarioRef: scenario.data.reference,
    rank: 1,
  };
  const task = await api(tenant).post<Pick<Task, 'reference'>>(
    '/v1/tasks',
    taskInput,
  );
  const stepInput: StepInputCreate = {
    label: i18next.t('scenarios:creation.modal.new-step.label'),
    taskRef: task.data.reference,
    rank: 1,
    newCondition: false,
  };
  const step = await api(tenant).post<Step>('/v1/steps', stepInput);

  return {
    scenario,
    task,
    step,
  };
}

export async function copyScenario(tenant: string, input: ScenarioInputCopy) {
  const { gears, medias, alertUsers, interventionUsers, siteId, label, lang } =
    input;
  const scenario = await api(tenant).post<Pick<Scenario, 'reference'>>(
    `v1/scenarios/id/${input.id}`,
    { gears, medias, alertUsers, interventionUsers, siteId, label },
    { params: { lang } },
  );

  return { scenario };
}

export async function uploadCsv(tenant: string, zoneId: number, sameZone: boolean ,file: any) {
  const fmData = new FormData();
  fmData.append('scenarios', file);
  const res = await api(tenant).post<Scenario>(
    `v1/scenarios/imports/zones/${zoneId}?sameZone=${sameZone}`,
    fmData,
  );
  return res;
}
