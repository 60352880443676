import React from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

interface GenericTableProps {
  dataSource?: any[];
  columns: ColumnsType<any>;
  pageSize: number;
  loading?: boolean;
  bordered?: boolean;
  className?: string;
  onRowClick?: (record: any) => void;
}

export default function GenericTable({
  dataSource,
  columns,
  pageSize,
  loading = false,
  bordered = false,
  className,
  onRowClick,
}: GenericTableProps) {
  return (
    <div>
      <Table
        rowKey="id"
        size="small"
        className={className}
        loading={loading}
        bordered={bordered}
        dataSource={dataSource}
        columns={columns}
        pagination={{ defaultPageSize: pageSize }}
        scroll={{ y: '55vh', scrollToFirstRowOnChange: true }}
        onRow={(record) => ({
          style: { cursor: onRowClick ? 'pointer' : 'initial' },
          onClick: () => onRowClick && onRowClick(record),
        })}
      />
    </div>
  );
}
