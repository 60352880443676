import { Intervention } from 'business/type';
import { api } from 'technical/api';

export async function getInterventionListFromZone(
  tenant: string,
  zoneId: number,
  startDate: string,
  endDate?: string
) {
  const interventionsList = api(tenant)
    .get<Intervention[]>(`/v1/interventions/zones/${zoneId}`, {
      params: {
        fromDate: startDate,
        toDate: endDate,
        full: true,
      },
    })
    .then((response) => response.data);

  return interventionsList;
}
