import {
  Scenario,
  Step,
  Task,
  Media,
  Subscription,
  InputRequest,
  Audit,
} from 'business/type';
import { api } from 'technical/api';
import i18next from 'i18next';
import {
  AnswerInputUpdate,
  InputRequestInputUpdate,
  StepInputCreate,
  StepInputUpdate,
  TaskInputCreate,
  TaskInputUpdate,
} from './type';

export interface ScenarioUpdate {
  id?: number;
  reference?: number;
  label?: string;
  prevRef?: number | null;
  mediasId?: number[];
}

export async function getScenarioDetail(tenant: string, reference: number) {
  const scenario = api(tenant)
    .get<Scenario>(`/v1/scenarios/${reference}`)
    .then((response) => response.data);

  return scenario;
}

export async function getScenarioDetailId(tenant: string, id: number) {
  const scenario = api(tenant)
    .get<Scenario>(`/v1/scenarios/id/${id}`)
    .then((response) => response.data);

  return scenario;
}

export async function getScenarioTasksAndSteps(
  tenant: string,
  reference: number,
) {
  const tasks = await api(tenant).get<Task[]>(
    `/v1/tasks/scenarios/${reference}?fullSteps=true`,
  );
  return tasks.data
    .sort((a, b) => a.rank - b.rank)
    .map((task) => {
      task.steps?.sort((a, b) => a.rank - b.rank);
      return task;
    });
}

export async function getScenarioTasksAndStepsId(tenant: string, id: number) {
  const tasks = await api(tenant).get<Task[]>(
    `/v1/tasks/scenarios/id/${id}?fullSteps=true`,
  );
  return tasks.data
    .sort((a, b) => a.rank - b.rank)
    .map((task) => {
      task.steps?.sort((a, b) => a.rank - b.rank);
      return task;
    });
}

export async function createTask(tenant: string, input: TaskInputCreate) {
  const task = await api(tenant).post<Pick<Task, 'id' | 'reference'>>(
    '/v1/tasks',
    input,
  );
  return task.data;
}

export async function updateTask(tenant: string, input: TaskInputUpdate) {
  const { reference, rank, label } = input;
  const task = await api(tenant).patch<Task>(`/v1/tasks/${reference}`, {
    rank,
    label,
  });
  return task.data;
}

export async function deleteTask(tenant: string, reference: number) {
  await api(tenant).delete(`/v1/tasks/${reference}`);
  return null;
}

export async function createStep(tenant: string, input: StepInputCreate) {
  const task = await api(tenant).post<Pick<Step, 'id' | 'reference'>>(
    '/v1/steps',
    input,
  );
  return task.data;
}

export async function updateStep(tenant: string, input: StepInputUpdate) {
  const {
    taskRef,
    stepRef,
    rank,
    label,
    description,
    gearsId,
    gearsOrder,
    answersRef,
    mandatoryValidation,
    mediasId,
    mediasOrder,
    zoneId,
  } = input;
  const step = await api(tenant).patch<Step>(`/v1/steps/${stepRef}`, {
    taskRef,
    rank,
    label,
    description,
    gearsId,
    gearsOrder,
    answersRef,
    mandatoryValidation,
    mediasId,
    mediasOrder,
    zoneId,
  });

  return step.data;
}

export async function updateAnswer(tenant: string, answer: AnswerInputUpdate) {
  await api(tenant).patch(`/v1/answers/${answer.reference}`, {
    ...answer,
  });
}

export async function removeZoneFromStep(tenant: string, stepId: number) {
  await api(tenant).delete(`/v1/zones/steps/${stepId}`);
}

export async function updateInputRequest(
  tenant: string,
  inputRequest: InputRequestInputUpdate,
) {
  await api(tenant).patch(`/v1/inputRequests/${inputRequest.reference}`, {
    ...inputRequest,
  });
}

export async function deleteInputRequest(tenant: string, inputRequest: number) {
  return api(tenant).delete(`/v1/inputRequests/${inputRequest}`);
}

export async function deleteAnswer(tenant: string, answer: number) {
  return api(tenant).delete(`/v1/answers/${answer}`);
}

export async function getMediaFromScenario(tenant: string, id: number) {
  const medias = api(tenant)
    .get<Media[]>(`/v1/medias/scenarios/id/${id}`)
    .then((response) => response.data);

  return medias;
}

export async function modifyScenario(tenant: string, input: ScenarioUpdate) {
  await api(tenant).patch(`/v1/scenarios/${input.reference}`, input);
}

export async function validateScenario(tenant: string, reference: number) {
  const res = await api(tenant).patch(`v1/scenarios/validate/${reference}`);

  return res.data;
}

export async function declineScenario(tenant: string, reference: number) {
  const res = await api(tenant).patch(`v1/scenarios/decline/${reference}`);

  return res.data;
}

export async function exportScenarioPDF(tenant: string, id: number) {
  const lang = i18next.language;
  const file = await api(tenant).get(
    `/v1/scenarios/exports/${id}?lang=${lang}`,
    { responseType: 'blob' },
  );
  return file.data;
}

export async function exportScenarioCSV(tenant: string, id: number) {
  const file = await api(tenant).get(`/v1/scenarios/exports/csv/${id}`, {
    responseType: 'blob',
  });
  return file.data;
}

export async function getMediaFromStep(tenant: string, stepId: number) {
  const medias = api(tenant)
    .get<Media[]>(`/v1/medias/steps/id/${stepId}`)
    .then((response) => response.data);

  return medias;
}

export async function getInputRequestsFromStep(tenant: string, stepId: number) {
  const medias = api(tenant)
    .get<InputRequest[]>(`/v1/inputRequests/steps/id/${stepId}`)
    .then((response) => response.data);

  return medias;
}

export async function getScenarioVersions(tenant: string, reference: number) {
  const scenarios = api(tenant)
    .get<Scenario[]>(`/v1/scenarios/versions/${reference}`)
    .then((response) => response.data);

  return scenarios;
}

export async function getDraftSubscription(tenant: string) {
  const { data: subscription } = await api(tenant).get<Subscription>(
    `/v1/subscriptions/versions`,
  );
  return subscription;
}

export async function getAuditsByScenarioRef(
  tenant: string,
  scenarioRef: number,
) {
  const audits = api(tenant)
    .get<Audit[]>(`/v1/audits/scenarios/${scenarioRef}`)
    .then((response) => response.data);

  return audits
}
